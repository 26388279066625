import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import SwitchButton from '../SwitchButton';
import ZoomComponent from '../ZoomComponent';
import useElementClickedOutside from 'Hooks/useElementClickedOutside';
// import { ReactComponent as UndoIcon } from '../Assets/navigationBarIcons/undo.svg';
import { ReactComponent as HomeIcon } from '../../Assets/Home.svg';
import { ReactComponent as PreviewIcon } from '../../Assets/navigationBarIcons/preview.svg';
import { ReactComponent as Arrow } from 'Assets/arrow-down.svg';
import { ReactComponent as Duplicate } from 'Assets/duplicate.svg';
import { ReactComponent as Choose } from 'Assets/choose.svg';
// import { ReactComponent as RocketSvg } from '../../Assets/rocketDark.svg';

import ToolbarWidgetAiQuestions from 'Components/Toolbar/ToolbarWidgetAiQuestions';

import {
  eventEmitter,
  rxCurrentPage,
  rxPageId,
  rxViewWidth,
  rxViewZoom,
  rxProjectId,
  rxExitModalEnabled,
  rxShowExitModal,
  rxBlocks,
  rxInitialBlocks,
} from '../../rx/rxState';
import { useObservable } from '../../utils/UseObservable';
import ExitModalButtons from '../ExitModalButtons';
import ToolbarBlocks from './ToolbarBlocks/ToolbarBlocks';

import { getDomain } from 'utils/functions';
import { getToken, isAdmin } from 'utils/api';
import { LeavePopup } from '../LeavePopup/LeavePopup';
import Tooltip from 'Components/Common/Tooltip/Tooltip';
import SavaAsTemplateModal from 'Components/Common/SaveAsTemplateModal/SaveAsTemplateModal';

import styles from './ToolbarPanel.module.scss';

const ToolbarStyle = styled.div`
  margin: auto 0px auto 0px;
  display: grid;
  grid-template-columns: 80px auto 380px;
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 2000;
  background-color: #eaedef;
  input,
  button,
  select,
  optgroup,
  textarea,
  .ant-input,
  .ant-tag,
  .ant-tag a,
  .ant-tag a:hover {
    color: #000;
  }

  .toolbar-block {
    margin-left: 15px;
    display: flex;
    align-items: center;
  }

  .controls {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    z-index: 2;
  }

  .navigation-block {
    z-index: 2;
    display: flex;
    border-right: 1px solid #d5d7da;
    font-size: 20px;
    color: #067fff;

    .slash {
      color: #000;
    }

    .parent-two {
      font-weight: bold;
    }

    .page-block {
      position: relative;
    }

    .page {
      cursor: pointer;
      display: inline-block;
      padding: 0 10px;
      border-radius: 15px 15px 0 0;

      &.nav-opened {
        color: #fff;
        background: #000000;

        &:after {
          content: '';
          display: block;
          width: 22px;
          height: 22px;
          background: #000000;
          position: absolute;
          bottom: -21px;
          left: 0px;
          z-index: 1;
        }
      }
    }
  }
`;

const LinkStyle = styled.a`
  display: flex;
  align-items: center;
  border: 0px #c6cacf solid;
  border-left: 1px solid #d5d7da;
  background: transparent;
  height: 33px;
  padding: 5px 18px;
  width: auto;
  &:hover {
    background: #000000;
    border-radius: 5px;
    color: #fff;
    svg {
      path {
        fill: #fff;
      }
    }
  }
`;

const ButtonStyle = styled.button`
  border: 0px #c6cacf solid;
  border-left: 1px solid #d5d7da;
  background: transparent;
  height: 33px;
  padding: 5px 18px;
  width: auto;
  display: flex;
  align-items: center;
  &:hover {
    background: #000000;
    border-radius: 5px;
    color: #fff;
      svg {
        path {
          fill: #fff;
        }
      }
    }
  }

  .publishIcon {
    width: 30px;
    height: 30px;
  }
`;

export const TOOLBAR_ID = 'toolbar-panel';

const ToolbarPanel = (props) => {
  const [pageBrowserOpen, setPageBrowserOpen] = useState(false);
  const [hasUserLoggedIn, setHasUserLoggedIn] = useState(false);
  const [popupVisible, setPopupVisible] = useState(false);
  const [isSaveTemplateVisible, setIsSaveTemplateVisible] = useState(false);
  const [isSaveTemplateModalVisible, setIsSaveTemplateModalVisible] =
    useState(false);
  const [isNewTemplate, setIsNewTemplate] = useState(false);
  const currentPage = useObservable(rxCurrentPage);
  const pageId = useObservable(rxPageId);
  const viewWidth = useObservable(rxViewWidth);
  const viewZoom = useObservable(rxViewZoom);
  const projectId = useObservable(rxProjectId);
  const showExitModal = useObservable(rxShowExitModal);
  const exitModalEnabled = useObservable(rxExitModalEnabled);
  const { templateId } = useParams();

  const { ref } = useElementClickedOutside(
    () => isSaveTemplateVisible && setIsSaveTemplateVisible(false)
  );

  useEffect(() => {
    const token = getToken();
    if (token) {
      setHasUserLoggedIn(true);
    } else {
      setHasUserLoggedIn(false);
    }
    console.log('hasUserLoggedIn:', hasUserLoggedIn);
  }, []);

  const onSaveClick = () => {
    eventEmitter.next({
      type: 'save',
      payload: {
        modalView: showExitModal,
      },
    });
  };

  const onDeviceChange = (value) => {
    rxViewWidth.next(value);
  };

  const handleLiveClick = () => {
    eventEmitter.next({ type: 'preview' });
    return false;
  };

  const navOpenedClass = pageBrowserOpen ? 'nav-opened' : '';

  const currentHost = window.location.origin;

  const consultLink = `//${
    getDomain().domain
  }/console/projects/${projectId}/refresh`;

  const handleSwitchShow = (value) => {
    eventEmitter.next({
      type: value ? 'switch-view-exit-modal' : 'switch-view-main-blocks',
    });
  };

  const handleExitModalEnabled = (value) => {
    eventEmitter.next({
      type: 'switch-exit-enabled',
      payload: {
        value,
      },
    });
  };

  const handleZoomChange = (direction) => {
    let delta = 0.15; // 15% difference;
    let zoom = viewZoom;
    zoom = zoom + delta * direction;

    if (zoom < 0.1) {
      zoom = 0.1;
    }
    if (zoom > 1.0) {
      zoom = 1.0;
    }
    rxViewZoom.next(zoom);
  };

  // const handlePublishClick = () => {
  //   props.onPublishClicked();
  // };

  const handleHomeClick = (e) => {
    eventEmitter.next({
      type: 'update-blocks',
      payload: {
        modalView: showExitModal,
      },
    });

    if (!hasUserLoggedIn) {
      e.preventDefault();
      e.stopPropagation();
      props.onHomeClicked();
    }

    if (
      props.initialSave === true &&
      JSON.stringify(rxBlocks.value.blocks) !==
        JSON.stringify(rxInitialBlocks.value.blocks)
    ) {
      setPopupVisible(true);
    } else {
      window.location.href = consultLink;
    }
  };

  const onSaveTemplate = () => {
    if (props.isTemplate) {
      if (templateId) {
        eventEmitter.next({
          type: 'update-template',
        });
      } else {
        setIsNewTemplate(true);
        setIsSaveTemplateModalVisible(true);
      }
    }
  };

  return (
    <>
      {props.isTemplate && isSaveTemplateModalVisible && (
        <SavaAsTemplateModal
          onClose={() => {
            setIsSaveTemplateModalVisible(false);
            setIsNewTemplate(false);
          }}
          isNew={isNewTemplate}
          templateValues={!isNewTemplate ? props.templateData : null}
        />
      )}

      <ToolbarStyle id={TOOLBAR_ID}>
        <div className="navigation-block">
          <div
            rel="noopener noreferrer"
            style={{ margin: 'auto' }}
            onClick={handleHomeClick}
          >
            <div style={{ display: 'flex' }}>
              <HomeIcon />
            </div>
          </div>
        </div>
        {popupVisible && (
          <LeavePopup
            onSave={onSaveClick}
            onClose={() => setPopupVisible(false)}
            projectId={projectId}
          />
        )}
        <ToolbarBlocks className="toolbar-block" />
        <div className="controls">
          <ExitModalButtons
            isModalEnabled={exitModalEnabled}
            isModalOpened={showExitModal}
            onChangeView={handleSwitchShow}
            onChangeEnabled={handleExitModalEnabled}
          />

          <SwitchButton
            value={viewWidth}
            onChange={(value) => onDeviceChange(value)}
          />
          {pageId && (
            <LinkStyle
              size="small"
              href={`${currentHost}/edit/view/${pageId}/${currentPage}`}
              target="_blank"
              onClick={() => handleLiveClick()}
            >
              <div style={{ position: 'relative', top: '4px' }}>
                <PreviewIcon />
              </div>
            </LinkStyle>
          )}
          {props.isTemplate ? (
            <>
              <div className={styles.saveButton} onClick={onSaveTemplate}>
                Save
              </div>
              <div className={styles.line} />
              <Tooltip
                overlayContent={
                  isSaveTemplateVisible && (
                    <div className={styles.tooltipContent} ref={ref}>
                      <div
                        className={styles.tooltipItem}
                        onClick={() => {
                          setIsSaveTemplateVisible(false);
                          setIsSaveTemplateModalVisible(true);
                          setIsNewTemplate(true);
                        }}
                      >
                        <Duplicate /> Save as New
                      </div>
                      <div
                        className={styles.tooltipItem}
                        onClick={() => {
                          setIsSaveTemplateVisible(false);
                          setIsSaveTemplateModalVisible(true);
                          setIsNewTemplate(!templateId);
                        }}
                      >
                        <Choose /> Save as Template
                      </div>
                    </div>
                  )
                }
                visible={isSaveTemplateVisible}
                placement="bottomLeft"
                overlayClassName={styles.saveTooltip}
                showArrow={false}
              >
                <div
                  className={styles.arrowButton}
                  onClick={() => setIsSaveTemplateVisible(true)}
                >
                  <Arrow />
                </div>
              </Tooltip>
            </>
          ) : (
            <ButtonStyle
              onClick={() => onSaveClick()}
              style={{ marginRight: '10px' }}
            >
              Save
            </ButtonStyle>
          )}

          {/* <ButtonStyle onClick={() => handlePublishClick()}>
          <RocketSvg className="publishIcon" />
          &nbsp; Publish
        </ButtonStyle> */}
        </div>

        <ZoomComponent
          value={viewZoom}
          onClick={(direction) => handleZoomChange(direction)}
        />

        {isAdmin() && <ToolbarWidgetAiQuestions />}
      </ToolbarStyle>
    </>
  );
};

export default ToolbarPanel;
