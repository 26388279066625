import clsx from 'clsx';
import { useEffect, useRef } from 'react';
import { ReactComponent as CloseSvg } from 'Assets/icons/x.svg';

import styles from './Popup.module.scss';

interface IProps {
  onClose: () => void;
  children: React.ReactNode;
  popupClassName?: string;
  wrapperClassName?: string;
}

const Popup = ({
  onClose,
  children,
  popupClassName,
  wrapperClassName,
}: IProps) => {
  const modalContentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const modalContent = modalContentRef.current;
    if (modalContent) {
      document.body.style.overflow = 'hidden';

      return () => {
        document.body.style.overflow = 'initial';
      };
    }
  }, []);

  return (
    <div className={clsx(styles.popup, popupClassName)} ref={modalContentRef}>
      <div className={styles.overlay} onClick={onClose} />
      <div className={clsx(styles.wrapper, wrapperClassName)}>
        <CloseSvg className={styles.closeIcon} onClick={onClose} />
        {children}
      </div>
    </div>
  );
};

export default Popup;
